import React from 'react';
import {Grid,Row,Col} from 'react-bootstrap';
const moment = require('moment');
import firebase from 'firebase/app';
import firestore from 'firebase/firestore';

class AdminWithdrawalManual extends React.Component {
   state = {
      withdrawal: {},
      isProcessed: false,
      isBusy: false
   };
   
   componentWillReceiveProps(nextProps) { 
      const { withdrawal } = nextProps;
      
      this.setState({ withdrawal });
   }
   
   flagAsProcessed = () => {
      const { withdrawal, isProcessed } = this.state;
      
      if (!isProcessed) {
         if (confirm('Are you sure you want to mark this withdrawal as processed?')) {
            const reference = prompt("Transaction Reference", "");
   
            if (reference !== null) {
               this.setState({
                  isBusy: true
               });
               window.db
                  .collection("private")
                  .doc("ledger")
                  .collection("withdrawal_requests")
                  .doc(withdrawal.id)
                  .update(
                     {
                        processed: true,
                        extReference: reference,
                        datePaid: firebase.firestore.FieldValue.serverTimestamp()
                     }
                  )
                  .then(() => {
                     this.setState({ isProcessed: true, isBusy: false });
                     // alert('Done!');
                  });
            } else {
               alert('Aborted!');
            }
         } else {
            alert('Aborted!');
         }
      }
   }
   
   render() {
      const { withdrawal, isProcessed, isBusy } = this.state;
      
      return (
         <div>
            {withdrawal && withdrawal.id && (
               <div>
                  <h2>Manual Withdrawal</h2>
                  
                  {!withdrawal.processed && !isProcessed && (
                     <Row style={{ paddingTop: 15, paddingBottom: 15 }}>
                        <Col xs={6} md={2}>
                           {!isBusy && (
                              <a href="javascript:void(0);" className="btn" style={{ marginTop: 10 }} onClick={this.flagAsProcessed}>Mark as Processed</a>
                           )}
                           {isBusy && (
                              <b>Processing...</b>
                           )}
                        </Col>
                     </Row>
                  )}
                  {(withdrawal.processed || isProcessed) && (
                     <Row style={{ paddingTop: 15, paddingBottom: 15 }}>
                        <Col xs={6} md={2}>
                           <b>Processed!</b>
                        </Col>
                     </Row>
                  )}
               </div>
            )}
         </div>
      );
   }
}

export default AdminWithdrawalManual;
