import React from 'react';
import {Grid,Row,Col} from 'react-bootstrap';
import brace from 'brace';
import AceEditor from 'react-ace';
import 'brace/mode/html';
import 'brace/theme/github';

class AdminCMS extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         assets: [],
         stateMessage: '',
         selectedAsset: {
            id: '',
            asset: {}
         }
      };
      
      this.onChange = this.onChange.bind(this);
      this.selectAsset = this.selectAsset.bind(this);
      this.updateAsset = this.updateAsset.bind(this);
      this.showStateMessate = this.showStateMessate.bind(this);
   }

   componentDidMount() {
      const that = this;
      
      if (this.props.isAdmin) {
         // Retrieve CMS Assets
         window.db
         .collection("private")
         .doc("cms")
         .collection("templates")
         .get()
         .then((snapshot) => {
            const cmsAssets = [];
            snapshot.docs.forEach((template) => {
               cmsAssets.push({
                  id: template.id,
                  asset: template.data()
               });
            });  
            that.setState({assets: cmsAssets});
            console.log(this.state.assets);
         });
      }
   }
   
   onChange(newValue) {
     //Update the value of selected asset (not commited yet)
     let selectedAsset = this.state.selectedAsset;
     selectedAsset.asset.body = newValue;
     this.setState({ selectedAsset });
   }
   
   selectAsset(asset) {
      this.setState({ selectedAsset: asset });
   }
   
   updateAsset() {
      //Update value of selected asset in database
      const that = this;
      
      if (this.props.isAdmin && 
          this.state.selectedAsset.id.length > 0) {
             
         // Retrieve CMS Assets
         window.db
         .collection("private")
         .doc("cms")
         .collection("templates")
         .doc(this.state.selectedAsset.id)
         .update(this.state.selectedAsset.asset)
         .then(() => {
            that.showStateMessate('Updated!');
         });
      }
   }
   
   showStateMessate( msg ) {
      this.setState({ stateMessage: msg} );
      window.setTimeout(() => {
         this.setState({ stateMessage: '' });
      },2000);
   }
   
   render() {
      return (
         <Grid>
            <Row>
               <Col xs={12}>
                  <h1>CMS</h1>
               </Col>
            </Row>
            <Row>
               <Col xs={3}>
                  {this.state.assets.map((asset,i) => 
                     <div key={i}>
                        <a href="javascript:void(0);" onClick={() => this.selectAsset(asset)}>{asset.id}</a>
                     </div>
                  )}
               </Col>
               <Col xs={9}>
                  <AceEditor
                      mode="html"
                      theme="github"
                      onChange={this.onChange}
                      name="assetBody"
                      width="100%"
                      wrapEnabled={true}
                      editorProps={{$blockScrolling: true}}
                      value={this.state.selectedAsset.asset.body}
                    />
                    <a href="javascript:void(0);" className="btn" style={{ marginTop: 10 }} onClick={this.updateAsset}>Update Asset</a> {this.state.stateMessage}
                    <hr />
                    <div dangerouslySetInnerHTML={{__html: this.state.selectedAsset.asset.body}} />
               </Col>
            </Row>
         </Grid>
      );
   }
}

export default AdminCMS;