import React from 'react';
import {Grid,Row,Col} from 'react-bootstrap';
import AdminPatientDetail from './Admin_Patient_Detail';
import AdminPatientLedger from './Admin_Patient_Ledger';

class AdminPatientSearch extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         searchText: '',
         patients: [],
         selectedPatient: {},
         selectedTab: 'ledger',
         error: ''
      };
   }

   componentDidMount() {
   }
   
   performSearch = () => {
      const { searchText } = this.state;
      
      this.setState({ error: '' });
      window.db
         .collection("patients")
         .where("keywords", "array-contains", searchText.toUpperCase())
         .get()
         .then((snapshot) => {
            const patients = [];
            snapshot.docs.forEach((patient) => {
               const data = patient.data();
               patients.push({
                  id: patient.id,
                  ...data
               });
            });  
            this.setState({patients});
            console.log(this.state.patients);
            
            if (snapshot.docs.length === 0) {
               this.setState({ error: 'No results found' });
            }
         });
   }
   
   selectPatient = (patient) => {
      this.setState({ selectedPatient: patient });
   }
   
   render() {
      const { searchText, patients, selectedPatient, selectedTab, error } = this.state;
      
      return (
         <Grid>
            <Row>
               <Col xs={3}>
                  <h1>Patients</h1>
                  <input type="text" value={searchText} onChange={(e) => this.setState({ searchText: e.target.value})} /><br />
                  <a href="javascript:void(0);" className="btn" style={{ marginTop: 10 }} onClick={this.performSearch}>Search</a>
                  <br /><br />
                  <strong>{error}</strong>
                  {patients.map((p,i) => (
                     <div key={i}>
                        <a href="javascript:void(0);" onClick={() => this.selectPatient(p)}>
                           {(p.firstName.length > 0 && p.lastName.length > 0) ? `${p.firstName} ${p.lastName}` : (p.nickName && p.nickName.length > 0) ? p.nickName : p.MSISDN}
                        </a>
                     </div>
                  ))}
               </Col>
               <Col xs={9}>
                  <AdminPatientDetail patient={selectedPatient} />
                  {selectedPatient && selectedPatient.id && (
                     <div style={{ backgroundColor: '#0f58d7', color: '#fff', borderRadius: 3, padding: 15, marginTop: 15, marginBottom: 15 }}>
                        <a href="javascript:void(0);" onClick={() => {this.setState({ selectedTab: 'ledger' });}}>Ledger</a>
                     </div>
                  )}
                  {selectedTab === 'ledger' && (
                     <AdminPatientLedger patient={selectedPatient} />
                  )}
               </Col>
            </Row>
         </Grid>
      );
   }
}

export default AdminPatientSearch;