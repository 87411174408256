import React from 'react';
import { Link } from 'react-router-dom';
import { Grid,Row,Col } from 'react-bootstrap';

class Admin extends React.Component {
   render() {
      return (
         <Grid>
            <Row>
               <Col xs={12}>
                  <h1>Admin</h1>
               </Col>
            </Row>
            <Row>
               <Col xs={12}>
                  <div><Link to="/admin_overview/">Overview</Link></div>
                  <div><Link to="/admin_patient_search/">Patients</Link></div>
                  <div><Link to="/admin_withdrawals/">Withdrawals</Link></div>
                  <div><Link to="/admin_doctor_registrations/">Doctor Applications</Link></div>
                  <div><Link to="/admin_cms/">CMS</Link></div>
               </Col>
            </Row>
         </Grid>
      );
   }
}

export default Admin;