import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import firebase from 'firebase/app';
import {Grid,Row,Col} from 'react-bootstrap';
const moment = require('moment');

class AdminPatientDetail extends React.Component {
    constructor(){
        super();
        
        this.state = {
        };
    }
    
    render() {
      const { patient } = this.props;
      
      if (patient && patient.id) {
        return (
          <div>
            <Row>
              <Col xs={12}>
                  <h2>{`${patient.firstName} ${patient.lastName} [${patient.MSISDN}]`}</h2>
              </Col>
            </Row>
            <Row style={{ paddingTop: 10, paddingBottom: 10 }}>
               <Col xs={6} md={2}>
                  <b>PATIENT</b>
               </Col>
               <Col xs={6} md={4}>
                  {`${patient.firstName} ${patient.lastName}`} ({patient.nickName ? patient.nickName : ''})
               </Col>
               <Col xs={6} md={2}>
                  <b>PATIENT ID</b>
               </Col>
               <Col xs={6} md={4}>
                  {patient.id}
               </Col>
            </Row>
            <Row style={{ paddingTop: 10, paddingBottom: 10 }}>
               <Col xs={6} md={2}>
                  <b>MSISDN</b>
               </Col>
               <Col xs={6} md={4}>
                  {patient.MSISDN}
               </Col>
               <Col xs={6} md={2}>
                  <b>CARRIER</b>
               </Col>
               <Col xs={6} md={4}>
                  {patient.carrierName}
               </Col>
            </Row>
            <Row style={{ paddingTop: 10, paddingBottom: 10 }}>
               <Col xs={6} md={2}>
                  <b>GENDER</b>
               </Col>
               <Col xs={6} md={4}>
                  {(patient.gender === 'M')?'MALE':'FEMALE'}
               </Col>
               <Col xs={6} md={2}>
                  <b>COUNTRY</b>
               </Col>
               <Col xs={6} md={4}>
                  {patient.countryName}
               </Col>
            </Row>
            <Row style={{ paddingTop: 10, paddingBottom: 10 }}>
               <Col xs={6} md={2}>
                  <b>DOB</b>
               </Col>
               <Col xs={6} md={4}>
                  {moment(patient.dob.toDate()).utc().format('YYYY-MM-DD')}
               </Col>
               <Col xs={6} md={2}>
                  <b>LAST SEEN</b>
               </Col>
               <Col xs={6} md={4}>
                  {patient.lastSeenDate && (
                     <div>{moment(patient.lastSeenDate.toDate()).format('YYYY-MM-DD HH:mm')}</div>
                  )}
               </Col>
            </Row>
            
          </div>
        );
      } else {
        return null;
      }
    }
}

export default AdminPatientDetail;