import React from 'react';
import { render } from 'react-dom';

import { BrowserRouter as Router, Route, Link, IndexRoute, Switch } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import App from './components/App';
import Home from './components/Home';
import About from './components/About';
import FAQ from './components/FAQ';
import Covid19 from './components/Covid19';
import Privacy from './components/Privacy';
import Conduct from './components/Conduct';
import Terms from './components/Terms';
import Contact from './components/Contact';
import Login from './components/Login';
import Admin from './components/Admin';
import AdminCMS from './components/Admin_CMS';
import AdminOverview from './components/Admin_Overview';
import AdminPatientSearch from './components/Admin_Patient_Search';
import AdminWithdrawals from './components/Admin_Withdrawals';
import AdminDoctorRegistrations from './components/Admin_Doctor_Registrations';
import AdminUploadMeds from './components/Admin_Upload_Meds';
import FourOhFour from './components/404';
import './styles/site.less';

// Initialize Firebase
import firebase from 'firebase/app';
import auth from 'firebase/auth';
import database from 'firebase/database';
import firestore from 'firebase/firestore';
//import messaging from 'firebase/messaging';
//import functions from 'firebase/functions';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIgloo, faAsterisk } from '@fortawesome/free-solid-svg-icons';
import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
library.add(faAndroid, faApple);

const isLIVE = false;

if (isLIVE || window.location.hostname.toLowerCase().indexOf('gogpplus.com') > -1) {
  // LIVE CREDENTIALS
  const config = {
    apiKey: "AIzaSyCaP80HO3aIbwacaxH9K5sBjnGhmCTDGno",
    authDomain: "gogp-9aeb3.firebaseapp.com",
    databaseURL: "https://gogp-9aeb3.firebaseio.com",
    projectId: "gogp-9aeb3",
    storageBucket: "gogp-9aeb3.appspot.com",
    messagingSenderId: "174221567631"
  };
  firebase.initializeApp(config);
  
  window._env = 'prod';
} else {
  // UAT CREDENTIALS
  var config = {
    apiKey: "AIzaSyBoEjLquyrrflkPT_DXBOpzti9n-FHgK1k",
    authDomain: "gogp-uat.firebaseapp.com",
    databaseURL: "https://gogp-uat.firebaseio.com",
    projectId: "gogp-uat",
    storageBucket: "gogp-uat.appspot.com",
    messagingSenderId: "239447951919"
  };
  firebase.initializeApp(config);
  
  window._env = 'uat';
}

// Initialize Cloud Firestore through Firebase
const db = firebase.firestore();
const funcs = firebase.functions();

db.settings({
  timestampsInSnapshots: true
});

window.db = db;
window.funcs = funcs;

//https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/basic-components.md
class AppRouter extends React.Component {
  constructor(){
    super();
    
    this.state = {
      loggedIn: false,
      isAdmin: false,
      userId: '',
      userName: ''
    };
  }
  
  componentWillMount(){
    const that = this;
    
    //Listen for authentication change
    firebase.auth().onAuthStateChanged(function(user) {
     if (user) {
       // User is signed in.
       var displayName = user.displayName;
       var email = user.email;
       var emailVerified = user.emailVerified;
       var photoURL = user.photoURL;
       var isAnonymous = user.isAnonymous;
       var uid = user.uid;
       var providerData = user.providerData;
       
       // Store logged in user
       that.setState({ userId: uid });
       that.setState({ userName: email.substring(0,email.indexOf('@')) });
       that.setState({ loggedIn: true });
       
       //Check if user is an admin
       db.collection("private").doc("perms").collection("is_admin").doc(uid).get().then((snapshot) => {
          const admin = snapshot.data();
          if( admin && admin.enabled ){
            that.setState({ isAdmin: true });
          }
       });

     } else {
       // User is signed out.
       that.setState({ userId: '' });
       that.setState({ userName: '' });
       that.setState({ loggedIn: false });
       that.setState({ isAdmin: false });
     }
    });
  }
  
  render(){
    const authProps = {
      loggedIn: this.state.loggedIn,
      isAdmin: this.state.isAdmin,
      userId: this.state.userId,
      userName: this.state.userName
    };
    
    return (
      <Router>
        <div>
          <Route 
            render={ props => <Header {...authProps} {...props} />}
          />
          
          <Switch>
            <Route path="/" exact component={props => <Home {...authProps} {...props} />} />
            <Route path="/about/" component={props => <About {...authProps} {...props} />} />
            <Route path="/FAQ/" component={props => <FAQ {...authProps} {...props} />} />
            <Route path="/COVID-19/" component={props => <Covid19 {...authProps} {...props} />} />
            <Route path="/privacy-policy/" component={props => <Privacy {...authProps} {...props} />} />
            <Route path="/code-of-conduct/" component={props => <Conduct {...authProps} {...props} />} />
            <Route path="/terms-of-use/" component={props => <Terms {...authProps} {...props} />} />
            <Route path="/contact/" component={props => <Contact {...authProps} {...props} />} />
            
            <Route path="/login/" component={props => <Login {...authProps} {...props} />} />
            <Route path="/admin/" component={props => (this.state.isAdmin && (<Admin {...authProps} {...props} />)) || <h2>Access Denied</h2>} />
            <Route path="/admin_cms/" component={props => (this.state.isAdmin && (<AdminCMS {...authProps} {...props} />)) || <h2>Access Denied</h2>} />
            <Route path="/admin_overview/" component={props => (this.state.isAdmin && (<AdminOverview {...authProps} {...props} />)) || <h2>Access Denied</h2>} />
            <Route path="/admin_patient_search/" component={props => (this.state.isAdmin && (<AdminPatientSearch {...authProps} {...props} />)) || <h2>Access Denied</h2>} />
            <Route path="/admin_withdrawals/" component={props => (this.state.isAdmin && (<AdminWithdrawals {...authProps} {...props} />)) || <h2>Access Denied</h2>} />
            <Route path="/admin_doctor_registrations/" component={props => (this.state.isAdmin && (<AdminDoctorRegistrations {...authProps} {...props} />)) || <h2>Access Denied</h2>} />
            <Route path="/admin_upload_meds/" component={props => (this.state.isAdmin && (<AdminUploadMeds {...authProps} {...props} />)) || <h2>Access Denied</h2>} />
            <Route component={props => <FourOhFour {...authProps} {...props} />} />
          </Switch>
          
          <Route 
            render={ props => <Footer {...authProps} {...props} />}
          />
        </div>
      </Router>
    );
  }
}

render(<AppRouter />, document.getElementById("app"));
