import React from 'react';
import {Grid,Row,Col} from 'react-bootstrap';

class About extends React.Component {
   render() {
      return (
         <Grid>
            <Row>
               <Col xs={12}>
                  <h1>About</h1>
               </Col>
            </Row>
         </Grid>
      );
   }
}

export default About;