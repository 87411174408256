import React from 'react';
import {Grid,Row,Col} from 'react-bootstrap';
const moment = require('moment');

class AdminPatientLedger extends React.Component {
   state = {
      patient: null,
      ledgerItems: [],
      newNarrative: '',
      newReference: '',
      newTransType: '',
      newAmount: '',
      showNewEntry: true
   };
   
   componentWillReceiveProps(nextProps) { 
      const { patient } = nextProps;
      
      // Determine if "showNewEntry" as supplied as a prop
      if (typeof nextProps.showNewEntry !== 'undefined') {
         const { showNewEntry } = nextProps;
         this.setState({ showNewEntry });
      }

      this.setState({ patient });
      if (patient && patient.id) {
         this.refreshLedger(patient.id);
      }
   }
   
   refreshLedger = (patientId) => {
      window.db
         .collection("patients")
         .doc(patientId)
         .collection("ledger")
         .orderBy("timestamp", "desc")
         .limit(500)
         .get()
         .then((snapshot) => {
            const ledgerItems = [];
            snapshot.docs.forEach((item) => {
               const data = item.data();
               ledgerItems.push({
                  id: item.id,
                  ...data
               });
            });  
            
            // console.log(ledgerItems);
            this.setState({ ledgerItems });
         });
   }
   
   addEntry = () => {
      const { patient, newNarrative, newReference, newTransType, newAmount } = this.state;
      const reference2 = '';

      if (newNarrative.length > 0 && newTransType.length > 0 && newAmount.length > 0 && !isNaN(newAmount)) {
         const postToLedger = window.funcs.httpsCallable('postToLedgerCall');
         postToLedger({
            userId: patient.id, 
            narrative: newNarrative, 
            transType: newTransType, 
            reference: newReference, 
            reference2, 
            amount: parseFloat(newAmount)
         }).then((transInfo) => {
            if (transInfo) {
               // Reset fields
               this.setState({
                  newNarrative: '',
                  newReference: '',
                  newAmount: ''
               });
               
               this.refreshLedger(patient.id);
            } else {
               alert('Problem encountered posting entry');
            }
         }).catch((httpsError) => {
           console.log(httpsError);
         });
      } else {
         alert('All fields must be supplied!');
      }
   }
   
   render() {
      const { patient, ledgerItems, newNarrative, newReference, newTransType, newAmount, showNewEntry } = this.state;
      
      return (
         <div>
            {patient && patient.id && (
               <div>
                  {showNewEntry && (
                     <Row style={{ backgroundColor: '#004fda', paddingTop: 15, paddingBottom: 15, borderRadius: 6 }}>
                        <Col xs={2}>
                           New Entry
                        </Col>
                        <Col xs={4}>
                           <input type="text" placeholder="Narrative" style={{ width: '100%' }} value={newNarrative} onChange={(e) => this.setState({ newNarrative: e.target.value })} /><br />
                           <input type="text" placeholder="Reference" style={{ width: '100%' }} value={newReference} onChange={(e) => this.setState({ newReference: e.target.value })} />
                        </Col>
                        <Col xs={2}>
                           <select style={{ width: '100%' }} onChange={(e) => this.setState({ newTransType: e.target.value })}>
                              <option value="">Type</option>
                              <option value="ADJ">ADJ</option>
                              <option value="DEP">DEP</option>
                              <option value="WTH">WTH</option>
                              <option value="CONSULT">CONSULT</option>
                              <option value="CONSULT_DOCTOR_SHARE">CONSULT_DOCTOR_SHARE</option>
                              <option value="CONSULT_REVENUE_SHARE">CONSULT_REVENUE_SHARE</option>
                              <option value="CONSULT_PARTNER_SHARE">CONSULT_PARTNER_SHARE</option>
                           </select>
                        </Col>
                        <Col xs={2} style={{ textAlign: "right" }}>
                           <input type="text" placeholder="Amount" style={{ width: '100%' }} value={newAmount} onChange={(e) => this.setState({ newAmount: e.target.value })} />
                        </Col>
                        <Col xs={2} style={{ textAlign: "right" }}>
                           <a href="javascript:void(0);" onClick={this.addEntry} className="btn">Submit</a>
                        </Col>
                     </Row>
                  )}
                  {ledgerItems.length > 0 && ledgerItems.map((item,i) => (
                     <Row key={i}>
                        <Col xs={2}>
                           {moment(item.timestamp.toDate()).local().format("DD-MM-YYYY HH:mm")}
                        </Col>
                        <Col xs={4}>
                           {item.narrative}
                        </Col>
                        <Col xs={2}>
                           {item.transType}
                        </Col>
                        <Col xs={2} style={{ textAlign: "right" }}>
                           {parseFloat(item.amount).toFixed(2)}
                        </Col>
                        <Col xs={2} style={{ textAlign: "right" }}>
                           {parseFloat(item.balance).toFixed(2)}
                        </Col>
                     </Row>
                  ))}
               </div>
            )}
         </div>
      );
   }
}

export default AdminPatientLedger;