import React from 'react';
import {Grid,Row,Col} from 'react-bootstrap';
const moment = require('moment');
import firebase from 'firebase/app';
import firestore from 'firebase/firestore';

class AdminWithdrawal extends React.Component {
   state = {
      withdrawal: {}
   };
   
   componentWillReceiveProps(nextProps) { 
      const { withdrawal } = nextProps;
      
      this.setState({ withdrawal });
   }
   
   render() {
      const { withdrawal } = this.state;
      
      return (
         <div>
            {withdrawal && withdrawal.id && (
               <div>
                  <h2>{`Withdrawal ${withdrawal.id}`}</h2>
                  <hr/>
                  {withdrawal.type === 'MM'
                     && (
                        <Row style={{ paddingTop: 15, paddingBottom: 15 }}>
                           <Col xs={6} md={2}>
                              <b>MSISDN</b>
                           </Col>
                           <Col xs={6} md={4}>
                              {withdrawal.MSISDN}
                           </Col>
                           <Col xs={6} md={2}>
                              <b>CARRIER</b>
                           </Col>
                           <Col xs={6} md={4}>
                              {withdrawal.carrierName}
                           </Col>
                        </Row>
                     )
                  }
                  {withdrawal.type === 'PAYPAL'
                     && (
                        <Row style={{ paddingTop: 15, paddingBottom: 15 }}>
                           <Col xs={6} md={2}>
                              <b>PAYPAL ADDRESS</b>
                           </Col>
                           <Col xs={6} md={4}>
                              {withdrawal.paypalAddress}
                           </Col>
                           <Col xs={6} md={2}>
                              <b></b>
                           </Col>
                           <Col xs={6} md={4}>
                              
                           </Col>
                        </Row>
                     )
                  }
                  <Row style={{ paddingTop: 15, paddingBottom: 15 }}>
                     <Col xs={6} md={2}>
                        <b>PATIENT</b>
                     </Col>
                     <Col xs={6} md={4}>
                        {(withdrawal.name.trim().length > 0)?withdrawal.name:'UNKNOWN'} ({withdrawal.nickName ? withdrawal.nickName : ''})
                     </Col>
                     <Col xs={6} md={2}>
                        <b>PATIENT ID</b>
                     </Col>
                     <Col xs={6} md={4}>
                        {withdrawal.userId}
                     </Col>
                  </Row>
                  <Row style={{ paddingTop: 15, paddingBottom: 15 }}>
                     <Col xs={6} md={2}>
                        <b>CREDITS</b>
                     </Col>
                     <Col xs={6} md={4}>
                        {withdrawal.credits}
                     </Col>
                     <Col xs={6} md={2}>
                        <b>RATE</b>
                     </Col>
                     <Col xs={6} md={4}>
                        1 CREDIT = {withdrawal.exchangeRate} {withdrawal.currency}
                     </Col>
                  </Row>
                  <Row style={{ paddingTop: 15, paddingBottom: 15 }}>
                     <Col xs={6} md={2}>
                        <b>WITHDRAWAL AMOUNT</b>
                     </Col>
                     <Col xs={6} md={4} style={{ fontSize: 25, fontWeight: 600 }}>
                        {withdrawal.amount} {withdrawal.currency}
                     </Col>
                     <Col xs={6} md={2}>
                        <b>WITHDRAWAL METHOD</b>
                     </Col>
                     <Col xs={6} md={4} style={{ fontSize: 25, fontWeight: 600 }}>
                        {withdrawal.type}
                     </Col>
                  </Row>
                  <Row style={{ paddingTop: 15, paddingBottom: 15 }}>
                     <Col xs={6} md={2}>
                        <b>PROCESSED?</b>
                     </Col>
                     <Col xs={6} md={4}>
                        {withdrawal.processed ? 'YES' : 'NO'}
                     </Col>
                     <Col xs={6} md={2}>
                        <b>CREATED</b>
                     </Col>
                     <Col xs={6} md={4}>
                        {moment(withdrawal.dateCreated.toDate()).format('YYYY-MM-DD HH:mm')}<br />
                        {withdrawal.debitId}
                     </Col>
                  </Row>
               </div>
            )}
         </div>
      );
   }
}

export default AdminWithdrawal;
