import React from 'react';
import {Grid,Row,Col} from 'react-bootstrap';

const Recaptcha = require('react-recaptcha');
const firebase = require('firebase');
require("firebase/functions");

class Contact extends React.Component {
   constructor(){
      super();
        
     this.state = {
        fullName: '',
        email: '',
        subject: '',
        message: '',
        recaptchaToken: '',
        error: '',
        isBusy: false,
        success: false
     };
   }
   
   componentDidMount(){
      //console.log("Hmm");
   }
   
   sendEmail = () => {
      const { fullName, email, subject, message, recaptchaToken, isBusy } = this.state;
      
      if (!isBusy) {
         if (fullName.length > 0 && email.length > 0 && subject.length > 0 && message.length > 0) {
            const sendClientTicket = firebase.functions().httpsCallable('sendClientTicket');
            
            this.setState({ isBusy: true });
            sendClientTicket({
                fromName: fullName,
                fromEmail: email,
                subject: subject, 
                assetName: 'email_contact', 
                substitutions: {
                   '[NAME]': fullName,
                   '[EMAIL]': email,
                   '[SUBJECT]': subject,
                   '[MESSAGE]': message
                },
                recaptchaToken
               
             }).then( result => {
                this.setState({ isBusy: false });
                console.log(result);
                
                const output = result.data;
                if (result.data.status) {
                   this.setState({ success: true });
                } else {
                   this.setState({ error: 'Sorry, a problem occurred.  Please try again!' });
                }
             }).catch( httpsError => {
               this.setState({ isBusy: false, error: 'Sorry, a problem occurred.  Please try again.' });
               console.log(httpsError);
             });
         } else {
            alert('Some fields were not filled out');
         }
      }
   }
   
   verifyCallback = (recaptchaToken) => {
     this.setState({ recaptchaToken });
   };
   
   render() {
      const { fullName, email, subject, message, error, success, isBusy } = this.state;
      return (
         <div>
            { !success && (
               <Grid>
                  <Row>
                     <Col xs={12} md={6} style={{ paddingBottom: 40 }}>
                        <h1>Contact</h1>
                        {error.length > 0 && (
                           <h4 style={{ marginTop: 20, marginBottom: 20 }}>{error}</h4>
                        )}
                        <input type="text" placeholder="Your full name" value={fullName} onChange={(e) => this.setState({ fullName: e.target.value})} style={{ width: '50%', fontSize: 18 }} /><br />
                        <input type="text" placeholder="Email address" value={email} onChange={(e) => this.setState({ email: e.target.value})} style={{ width: '50%', fontSize: 18 }} /><br />
                        <input type="text" placeholder="Subject" value={subject} onChange={(e) => this.setState({ subject: e.target.value})} style={{ width: '100%', fontSize: 18 }} /><br />
                        <textarea placeholder="Message" value={message} onChange={(e) => this.setState({ message: e.target.value})} style={{ width: '100%', height: 120, fontSize: 18 }} /><br />
                        <Recaptcha
                           sitekey="6LfUu5YUAAAAAB0vY5NYzmwQswjnwxAB0mavKcY7"
                           verifyCallback={this.verifyCallback}
                        />
                        <a href="javascript:void(0);" className="btn" style={{ marginTop: 10 }} onClick={this.sendEmail}>Submit</a>
                     </Col>
                     <Col xs={12} md={6}>
                        <h2>Please Note</h2>
                        <div style={{ fontSize: 21 }}>
                           <p>This contact form is for general enquiries regarding the GoGP service only, and not for medical consultations.</p>
                           <p>If your question is in regard to a medical condition, please either request a consultation via the GoGP app, or
                           contact your family doctor / local medical center.</p>
                           <br /><br />
                           <h3>Physical Address</h3>
                           <p>
                              7455 ARROYO CROSSING PKWY # 220<br />
                              LAS VEGAS<br />
                              Nevada<br />
                              89113
                           </p>
                        </div>
                     </Col>
                  </Row>
               </Grid>
            )}
            {success && (
               <center>
                  <h1>Thank You</h1>
                  <h2>Message Sent Successfully!</h2>
               </center>
            )}
         </div>
      );
   }
}

export default Contact;