import React from 'react';
import {Grid,Row,Col} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Home extends React.Component {
   render() {
      return (
         <Grid>
            <Row>
               <Col xs={12} style={{ paddingBottom: 40 }}>
                  <center>
                     <img src={require('../assets/gogp-logo.png')} style={{ maxWidth: 483 }} className="imgResponsive" />
                  </center>
               </Col>
            </Row>
            <Row>
               <Col xs={12} md={6} style={{ paddingBottom: 40 }}>
                  <div style={{ borderLeftWidth: 8, borderLeftColor: '#fff', borderLeftStyle: 'solid', paddingLeft: 20 }}>
                     <h1 style={{ fontSize: 80 }}>Healthcare<br/>for the<br/>Masses</h1>
                  </div>
                  <div style={{ fontSize: 22, marginTop: 30 }}>
                     At GoGP+, our mission is to put an accessible and affordable health service in the hands of every 
                     person with a phone, with special emphasis to the disadvantaged.
                  </div>
                  <div style={{ marginTop: 50 }}>
                     <span style={{ borderWidth: 3, borderRadius: 12, borderStyle: 'solid', borderColor: '#fff', padding: 20, fontSize: 25, fontWeight: 600 }}>
                        <a href="https://play.google.com/store/apps/details?id=com.werdego.gogp" style={{ textDecoration: 'none' }}>
                           <FontAwesomeIcon icon={['fab', 'android']} /> &nbsp;Android
                        </a>
                     </span>
                     <span style={{ borderWidth: 3, borderRadius: 12, borderStyle: 'solid', borderColor: '#fff', padding: 20, fontSize: 25, fontWeight: 600, marginLeft: 20 }}>
                        <a href="https://appstore.com/golabs" style={{ textDecoration: 'none' }}>
                           <FontAwesomeIcon icon={['fab', 'apple']} /> &nbsp;iPhone
                        </a>
                     </span>
                  </div>
               </Col>
               <Col xs={12} md={6}>
                  <img src={require('../assets/phone-mock.png')} className="imgResponsive" />
               </Col>
            </Row>
         </Grid>
      );
   }
}

export default Home;