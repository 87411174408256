import React from 'react';
import {Grid,Row,Col} from 'react-bootstrap';
import AdminDoctorRegistration from './Admin_Doctor_Registration';

const moment = require('moment');

class AdminDoctorRegistrations extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         searchText: '',
         registrations: [],
         selectedRegistration: {},
         registrationsRef: null
      };
   }

   componentDidMount() {
      const registrationsRef = window.db
         .collection("doctor_registrations")
         .where("status", "==", "PENDING")
         .orderBy("nextReview", "asc")
         .limit(500)
         .onSnapshot((snapshot) => {
            // console.log(snapshot);
            if (snapshot) {
               const changes = snapshot.docChanges();
               changes.forEach((change) => {
                  let request = change.doc.data();
                  
                  request = {
                     id: change.doc.id,
                     ...request
                  };
                  
                  let { registrations } = this.state;
                  const requests = registrations;
                  
                  if (change.type === 'added') {
                     requests.push(request);
                  } else if (change.type === 'modified' && !request.processed) {
                   /*
                     Request changed
                   */
                   requests.forEach((req, i) => {
                     if (req.id === request.id) {
                       requests[i] = request;
                       this.selectRegistration(request);
                     }
                   });
                 } else if (change.type === 'removed' || request.processed) {
                   /*
                     Request removed
                   */
                   let foundIdx = -1;
                   requests.forEach((req, i) => {
                     if (req.id === request.id) {
                       foundIdx = i;
                     }
                   });

                   if (foundIdx > -1) {
                     requests.splice(foundIdx, 1);
                   }
                   
                   // Check if this was the currently selected registration
                   // If so deselect it.
                   const { selectedRegistration } = this.state;
                   if (selectedRegistration.id === request.id) {
                      this.setState({ selectedRegistration: {} });
                   }
                 }
                 
                 this.setState({ registrations: requests });
               });  
            }
         });
      this.setState({registrationsRef});
   }
   
   componentWillUnmount() {
      const { registrationsRef } = this.state;
      
      if (registrationsRef) {
         registrationsRef();
      }
   }
   
   getDoctorName(registration) {
      if (registration.displayName && registration.displayName.trim().length > 0) {
         return registration.displayName;
      } else if (registration.practiceName && registration.practiceName.trim().length > 0) {
         return registration.practiceName;
      } else {
         return "Not Supplied";
      }
   }
   
   selectRegistration = (registration) => {
      this.setState({ selectedRegistration: registration });
   }
   
   render() {
      const { registrations, selectedRegistration } = this.state;

      return (
         <Grid>
            <Row>
               <Col xs={12}>
                  <h1>Pending Doctor Applications</h1>
               </Col>
            </Row>
            <Row>
               <Col xs={3}>
                  {registrations.map((r,i) => (
                     <div key={i} style={{
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderColor: '#004fda',
                        padding: '10px',
                        backgroundColor: ((selectedRegistration.id === r.id) ? '#004fda' : '#296cf6')
                     }}>
                        <a href="javascript:void(0);" onClick={() => this.selectRegistration(r)}>{this.getDoctorName(r)}</a><br />
                        {r.nextReview && (
                           moment(r.nextReview.toDate()).format('YYYY-MM-DD HH:mm')
                        )}
                        <br />
                        {r.practiceCountry}
                     </div>
                  ))}
               </Col>
               <Col xs={9}>
                  <AdminDoctorRegistration userId={this.props.userId} userName={this.props.userName} registration={selectedRegistration} />
               </Col>
            </Row>
         </Grid>
      );
   }
}

export default AdminDoctorRegistrations;