import React from 'react';
import firebase from 'firebase/app';
import {Grid,Row,Col} from 'react-bootstrap';

class Login extends React.Component {
   constructor(){
      super();
      this.state = {
         email: '',
         password: ''
      };
      
      // This binding is necessary to make `this` work in the callback
      this.handleSignIn = this.handleSignIn.bind(this);
      this.handleOAuth  = this.handleOAuth.bind(this);
   }
   
   handleSignIn(){
      const that = this;
      
      firebase
      .auth()
      .signInWithEmailAndPassword(this.state.email, this.state.password)
      .then((result) => {
         if (result.user && result.user.uid && result.user.uid.length > 0) {
            that.props.history.push('/');
         }
      })
      .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        
        if (errorCode === 'auth/wrong-password') {
          alert('Wrong password.');
        } else {
          alert(errorMessage);
        }
        console.log(error);
      });
   }
   
   handleOAuth(){
      var provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      const that = this;

      firebase
      .auth()
      .signInWithPopup(provider)
      .then(function(result) {
          // This gives you a Google Access Token.
          //var token = result.credential.accessToken;
          // The signed-in user info.
          //var user = result.user;
          
          if (result.user && result.user.uid && result.user.uid.length > 0) {
            that.props.history.push('/');
          }
      })
      .catch(function(error) {
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        
        console.log(error);
      });
   }

   render() {
      return (
         <Grid>
            <Row>
               <Col xs={12}>
                  <center>
                     <h1>Sign In</h1>
                     <input type="text" placeholder="Email" value={this.state.email} onChange={(e) => this.setState({email: e.target.value})}  />
                     <br />
                     <input type="password" placeholder="Password" value={this.state.password} onChange={(e) => this.setState({password: e.target.value})} />
                     <br />
                     <a href="javascript:void(0);" className="btn" style={{ marginTop: 10 }} onClick={this.handleSignIn}>Sign In</a>
                     <br />
                     <button className="btn" style={{ marginTop: 10, color: '#ffffff' }} ref="googleLoginBtn" onClick={this.handleOAuth}>Sign in with Google</button>
                  </center>
               </Col>
            </Row>
         </Grid>
      );
   }
}

export default Login;