import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import firebase from 'firebase/app';
import {Grid,Row,Col} from 'react-bootstrap';

class Header extends React.Component {
    constructor(){
        super();
        
        this.state = {
        };
        
        // This binding is necessary to make `this` work in the callback
        this.handleSignOut = this.handleSignOut.bind(this);
    }

    handleSignOut(){
        //Sign user out
        firebase.auth().signOut();
        this.props.history.push('/');
    }
    
    render() {
        const { pathname } = this.props.location;
        
      return (
        <Grid>
          <Row className="headerBar">
            <Col xs={12}>
                <center>
                    {pathname !== '/'
                        && (
                            <div>
                                <Link to="/"><img src={require('../assets/gogp-logo.png')} style={{ width: 160, height: 'auto', marginBottom: 20, border: 0 }} /></Link>
                            </div>
                        )
                    }
                    
                    <Link to="/">Home</Link>
                    <Link to="/FAQ/">FAQ</Link>
                    <Link to="/COVID-19/">COVID-19</Link>
                    <Link to="/contact/">Contact</Link>
                    { this.props.isAdmin &&
                        <Link to="/admin/">Admin</Link>
                    } 
                    { !this.props.loggedIn &&
                        <Link to="/login/">Sign In</Link>
                    } 
                    { this.props.loggedIn &&
                        <a href="javascript:void(0);" onClick={this.handleSignOut}>Sign Out</a>
                    } 
                </center>
            </Col>
          </Row>
        </Grid>
      );
    }
}

export default Header;