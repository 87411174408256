import React from 'react';
import {Grid,Row,Col} from 'react-bootstrap';
const moment = require('moment');
import firebase from 'firebase/app';
import firestore from 'firebase/firestore';

class AdminOverview extends React.Component {
   state = {
      doctorsOnline: [],
      consultationRequests: [],
      doctorsRef: null,
      requestsRef: null,
      timerRef: null,
      updateTimerRef: null,
      statisticsTimerRef: null,
      forceUpdate: false,
      consultsLastHour: 0,
      consultsLast2Hours: 0,
      consultsLast24Hours: 0,
      consultsLast7Days: 0,
      consultsLast14Days: 0,
      consultsLast30Days: 0
   };
   
   componentDidMount() {
      const doctorsRef = window.db
        .collection('doctors')
        .where('approved',  '==', true)
        .where('available', '==', true)
        .orderBy('lastSeenDate', 'desc')
        .onSnapshot((snapshot) => {
          const online = [];
          if (snapshot) {
            const changes = snapshot.docChanges();
            
            changes.forEach((change) => {
              let doctor = change.doc.data();
              
              doctor = {
                 id: change.doc.id,
                 ...doctor
              };
              
              // Ensure we've seen the doctor in the last 2 hours
              let isOnline = false;
              if (doctor.lastSeenDate) {
                const duration = moment.duration(moment().utc().diff(doctor.lastSeenDate.toDate()));
                const minutes = duration.asMinutes();

                if (minutes <= 120) {
                  isOnline = true;
                }
              }
              
              let { doctorsOnline } = this.state;
              const doctors = doctorsOnline;
              
              if (change.type === 'added' && isOnline) {
                 doctors.push(doctor);

              } else if (change.type === 'modified') {
               /*
                 Doctor details changed
               */
               doctors.forEach((d, i) => {
                 if (d.id === doctor.id) {
                   doctors[i] = doctor;
                 }
               });

             } else if (change.type === 'removed') {
               /*
                 Doctor no longer approved/available
               */
               let foundIdx = -1;
               doctors.forEach((d, i) => {
                 if (d.id === doctor.id) {
                   foundIdx = i;
                 }
               });

               if (foundIdx > -1) {
                 doctors.splice(foundIdx, 1);
               }
             }
             
             this.setState({ doctorsOnline: doctors });
           });  
          }
        });
        
        
     const requestsRef = window.db
        .collection('consultation_requests')
        .orderBy('requestDate', 'desc')
        .onSnapshot((snapshot) => {
          if (snapshot) {
            const changes = snapshot.docChanges();
            
            changes.forEach((change) => {
              let request = change.doc.data();
              
              request = {
                 id: change.doc.id,
                 ...request
              };
              
              let { consultationRequests } = this.state;

              if (change.type === 'added') {
                 consultationRequests.push(request);

              } else if (change.type === 'modified') {
               /*
                 Consultation request changed
               */
               consultationRequests.forEach((r, i) => {
                 if (r.id === request.id) {
                   consultationRequests[i] = request;
                 }
               });

             } else if (change.type === 'removed') {
               /*
                 Consultation request was cancelled
               */
               let foundIdx = -1;
               consultationRequests.forEach((r, i) => {
                 if (r.id === request.id) {
                   foundIdx = i;
                 }
               });

               if (foundIdx > -1) {
                 consultationRequests.splice(foundIdx, 1);
               }
             }
             
             this.setState({ consultationRequests });
           });  
          }
        });
        
      // Set timer to remove doctors from array if last seen over 2 hours
      // This is done because they may not set their status to unavailable, so their status won't change
      // This removes 1 doctor from array every 2 seconds
      const timerRef = window.setInterval(() => {
        let { doctorsOnline } = this.state;
        const doctors = doctorsOnline;
        
        let foundIdx = -1;
        doctors.forEach((d, i) => {
          const duration = moment.duration(moment().utc().diff(d.lastSeenDate.toDate()));
          const minutes = duration.asMinutes();

          if (minutes > 120) {
            foundIdx = i;
          }
        });
        
        if (foundIdx > -1) {
          doctors.splice(foundIdx, 1);
          this.setState({ doctorsOnline: doctors });
        }
      }, 2000);
      
      this.setState({timerRef});
      
      // Force UI to refresh every 60 seconds
      const updateTimerRef = window.setInterval(() => {
        const { forceUpdate } = this.state;
        this.setState({ forceUpdate: !forceUpdate });
      }, 1000 * 60);
      
      this.setState({updateTimerRef});
      
      // Force statistics update every 10 minutes
      const statisticsTimerRef = window.setInterval(() => {
          this.updateStatistics();
      }, 1000 * 60 * 10);
      this.updateStatistics();
   }
   
   componentWillUnmount() {
      const { doctorsRef, requestsRef, timerRef, updateTimerRef, statisticsTimerRef } = this.state;
      
      if (doctorsRef) {
         doctorsRef();
      }
      
      if (requestsRef) {
         requestsRef();
      }
      
      if (timerRef) {
        window.clearInterval(timerRef);
      }
      
      if (updateTimerRef) {
        window.clearInterval(updateTimerRef);
      }
      
      if (statisticsTimerRef) {
        window.clearInterval(statisticsTimerRef);
      }
   }
   
   updateStatistics() {
       console.log("Updating statistics...");
       window.db
         .collection("consultations")
         .where("consultationDate", ">", moment().utc().subtract(31, "days").toDate())
         .orderBy("consultationDate", "desc")
         .limit(400)
         .get()
         .then((snapshot) => {
            let consultsLastHour = 0;
            let consultsLast2Hours = 0;
            let consultsLast24Hours = 0;
            let consultsLast7Days = 0;
            let consultsLast14Days = 0;
            let consultsLast30Days = 0;
            
            snapshot.docs.forEach((c) => {
               const consult = c.data();
               if (consult.closedAt) {
                   const durationClosedUntilNow = moment.duration(moment().utc().diff(consult.consultationDate.toDate()));
                   const durationConsultation = moment.duration(moment(consult.closedAt.toDate()).diff(consult.consultationDate.toDate()));
                   //console.log(durationClosedUntilNow.asHours());
                   //console.log(durationClosedUntilNow.asDays());
                   if (durationClosedUntilNow.asHours() <= 1) {
                       consultsLastHour++;
                   }
                   if (durationClosedUntilNow.asHours() <= 2) {
                       consultsLast2Hours++;
                   }
                   if (durationClosedUntilNow.asHours() <= 24) {
                       consultsLast24Hours++;
                   }
                   if (durationClosedUntilNow.asDays() <= 7) {
                       consultsLast7Days++;
                   }
                   if (durationClosedUntilNow.asDays() <= 14) {
                       consultsLast14Days++;
                   }
                   if (durationClosedUntilNow.asDays() <= 30) {
                       consultsLast30Days++;
                   }
               }
            });  
            this.setState({
              consultsLastHour,
              consultsLast2Hours,
              consultsLast24Hours,
              consultsLast7Days,
              consultsLast14Days,
              consultsLast30Days
            });
            console.log("Finished updating statistics");
         });
   }
   
   render() {
      const {
          doctorsOnline,
          consultationRequests,
          forceUpdate,
          consultsLastHour,
          consultsLast2Hours,
          consultsLast24Hours,
          consultsLast7Days,
          consultsLast14Days,
          consultsLast30Days
      } = this.state;
      
      return (
         <Grid>
            <h2>Statistics</h2>
            <Row style={{ padding: 20, backgroundColor: '#015CFB', borderRadius: 5 }}>
                <Col xs={3}><strong>Consultations Completed</strong></Col>
                <Col xs={3}>1 Hour: <strong>{consultsLastHour}</strong></Col>
                <Col xs={3}>2 Hours: <strong>{consultsLast2Hours}</strong></Col>
                <Col xs={3}>24 Hours: <strong>{consultsLast24Hours}</strong></Col>
            </Row>
            <Row style={{ padding: 20, backgroundColor: '#015CFB', borderRadius: 5 }}>
                <Col xs={3}></Col>
                <Col xs={3}>7 Days: <strong>{consultsLast7Days}</strong></Col>
                <Col xs={3}>14 Days: <strong>{consultsLast14Days}</strong></Col>
                <Col xs={3}>30 Days: <strong>{consultsLast30Days}</strong></Col>
            </Row>
            <Row>
               <Col xs={12} md={6}>
                  <h2>Doctors Online (last 2 hours)</h2>
                  {doctorsOnline.length === 0 && (
                    <Row>
                      <Col xs={12}>
                        No doctors online
                      </Col>
                    </Row>
                  )}
                  {doctorsOnline.map((d,i) => (
                     <Row key={i} style={{ paddingTop: 15, paddingBottom: 15 }}>
                        <Col md={4}>
                           {d.firstName} {d.lastName}
                        </Col>
                        <Col md={4}>
                           <strong>last seen</strong> {moment(d.lastSeenDate.toDate()).fromNow()}
                        </Col>
                        <Col md={4}>
                           <i>{d.specialities.join(', ')}</i>
                        </Col>
                     </Row>
                  ))}
               </Col>
               <Col xs={12} md={6}>
                  <h2>Patients Waiting ({consultationRequests.length})</h2>
                  {consultationRequests.length === 0 && (
                    <Row>
                      <Col xs={12}>
                        No patients waiting
                      </Col>
                    </Row>
                  )}
                  {consultationRequests.map((r,i) => (
                     <Row key={i} style={{ paddingTop: 15, paddingBottom: 15 }}>
                        <Col md={4}>
                           {r.patientName}
                        </Col>
                        <Col md={4}>
                           <strong>Requested</strong> <i>{r.specialty}</i>
                        </Col>
                        <Col md={4}>
                           {moment(r.requestDate.toDate()).fromNow()}
                        </Col>
                     </Row>
                  ))}
               </Col>               
            </Row>
            { forceUpdate && (
              <span></span>
            ) }
         </Grid>
      );
   }
}

export default AdminOverview;
