import React from 'react';
import {Grid,Row,Col} from 'react-bootstrap';

class FAQ extends React.Component {
   render() {
      return (
         <Grid>
            <Row>
               <Col xs={12}>
                  <h1>Frequently Asked Questions</h1>
                  
                  <ul style={{ listStyleType: 'none', fontSize: 35, marginTop: 30, color: '#16e721' }}>
                     <li>
                        <h2>Video disappeared during a consultation, what's wrong?</h2>
                        <div style={{ fontSize: 18, marginBottom: 60, color: '#fff' }}>
                           <p>This can happen for a number of reasons, most commonly it is caused by poor network coverage.</p>
                           <p>We are actively working on improving our ability to resume video in these situations (when your connection permits).</p>
                           <p>Meanwhile if this happens, please continue your conversation with the doctor via audio chat.</p>
                           <p>You can still upload photos, use text chat, and other features of the application.</p>
                        </div>
                     </li>
                     <li>
                        <h2>From what countries can I use GoGP?</h2>
                        <div style={{ fontSize: 18, marginBottom: 60, color: '#fff' }}>
                           <p>As a patient, GoGP can currently be used only within Uganda, Africa.  However additional countries will be coming online shortly.</p>
                           <p>Any doctor can register with GoGP, however only doctors within Uganda can currently withdraw funds.</p>
                        </div>
                     </li>
                     <li>
                        <h2>As a doctor can I offer pro bono services?</h2>
                        <div style={{ fontSize: 18, marginBottom: 60, color: '#fff' }}>
                           <p>GoGP welcomes doctors wishing to help the under privileged and disadvantaged.</p>
                           <p>The full amount or a portion of the proceeds of your consultations can be donated to a local community charity via the app.</p>
                           <p>Please download the app and register as a doctor.</p>
                        </div>
                     </li>
                     <li>
                        <h2>How do I speak to a GoGP doctor?</h2>
                        <div style={{ fontSize: 18, marginBottom: 60, color: '#fff' }}>
                           <p>You can speak to a Go GP on any mobile or fixed device that’s attached to the internet and can download the GoGP+ app.</p>
                           <p>You will need to create a login with a username and password.</p>
                           <p>Then you will need to load your account with call credits then request a consultation from a General Practitioner or Specialist.</p>
                        </div>
                     </li>
                     <li>
                        <h2>Can I use the service from anywhere?</h2>
                        <div style={{ fontSize: 18, marginBottom: 60, color: '#fff' }}>
                           <p><strong>Yes you can.</strong></p>
                           <p>You will need to have a device that’s connected to the internet and you’d have downloaded the GOGP+ app to your device.</p>
                        </div>
                     </li>
                     <li>
                        <h2>Does GoGP treat children?</h2>
                        <div style={{ fontSize: 18, marginBottom: 60, color: '#fff' }}>
                           <p><strong>Yes.</strong></p>
                           <p>Adults can use our services on behalf of children, but children shouldn't use our services themselves Children under 16 may have an account opened for them by a parent or legal guardian, and if the parent or legal guardian supervises the child’s use of our services at all times.</p>
                           <p>Young people between 16 and 18 may use our services and open their own account with us.</p>
                        </div>
                     </li>
                     <li>
                        <h2>Do GoGP doctors have your medical history?</h2>
                        <div style={{ fontSize: 18, marginBottom: 60, color: '#fff' }}>
                           <p>The main type of information we hold about you is health and medical information: information about your health, symptoms, treatments, consultations and sessions, medications and procedures. This includes details of your consultations with our doctors.</p>
                           <p>We get some of this information directly from you, when you register with us and when you use our healthcare services.</p>
                           <p>This information is important when the doctors are making decisions about your health care, and is held with utmost privacy.</p>
                        </div>
                     </li>
                     <li>
                        <h2>How will GoGP doctors treat without a physical contact?</h2>
                        <div style={{ fontSize: 18, marginBottom: 60, color: '#fff' }}>
                           <p>The doctors will be available to speak to patients through video chatting or text chatting remotely through the GOGP+ app.</p>
                           <p>This will be especially useful to those who live in rural areas or for whom travel to a medical 
                              facility is difficult (or even for busy working parents).</p>
                           <p>Because it can save travel time and expense for providers and patients the app will improve the 
                              chances that people will receive preventive care and better management of chronic conditions.</p>
                           <p>It will also facilitate specialist consultations, whether the provider is across the country, 
                              continent or across the world.</p>
                           <p>Not all consultations require physical contact and those that require to be examined will be 
                              referred appropriately.</p>
                        </div>
                     </li>
                     <li>
                        <h2>How do I pay for a consultation?</h2>
                        <div style={{ fontSize: 18, marginBottom: 60, color: '#fff' }}>
                           <p>You can load the fees to pay for the service by using services like MTN mobile money, Airtel money etc.</p>
                           <p>Subscriptions available within the App payable up-front in advance.</p>
                           <p>For your convenience payment for the services will appear as credits. The fee for a credit will be approximately 4 dollars per credit but may vary from time to time.</p>
                           <p>It will cost one credit to take a consultation with the GP and two credits to take a consultation with a specialist.</p>
                        </div>
                     </li> 
                     <li>
                        <h2>What times can I get a consultation?</h2>
                        <div style={{ fontSize: 18, marginBottom: 60, color: '#fff' }}>
                           <p>The services will be available and accessible 24 hours a day.</p>
                        </div>
                     </li>  
                     <li>
                        <h2>Why have I been advised to see someone face to face?</h2>
                        <div style={{ fontSize: 18, marginBottom: 60, color: '#fff' }}>
                           <p>In some cases the consultation may be difficult to conclude over the phone and on video and you may require immediate medical attention or further evaluation by physical examination and tests. In this case you will be advised to attend any of our partner facilities or the nearest medical facility.</p>
                        </div>
                     </li>  
                     <li>
                        <h2>How do GoGP prescriptions work?</h2>
                        <div style={{ fontSize: 18, marginBottom: 60, color: '#fff' }}>
                           <p>During the consultation the doctor will fill out a prescription in the app.</p>
                           <p>At the end of the consultation the copy of the consultation will be emailed to the patient. This can be printed out and filled by any licensed pharmacy.</p>
                           <p>The alternative will be that the patient requests us to arrange a delivery of the medicines. We will liaise directly with our partner pharmacies and deliver the medicine to the patient after they’ve paid the appropriate fee.</p>
                        </div>
                     </li>  
                     <li>
                        <h2>Can GoGP doctors tell me the cost of medication?</h2>
                        <div style={{ fontSize: 18, marginBottom: 60, color: '#fff' }}>
                           <p>The doctors may not be able to tell you the cost of the medicines immediately but if you opt for us to deliver the medicines you will be called back with an exact amount after confirming with our partner pharmacies before delivery is arranged.</p>
                        </div>
                     </li> 
                     <li>
                        <h2>Do you offer a repeat prescription service?</h2>
                        <div style={{ fontSize: 18, marginBottom: 60, color: '#fff' }}>
                           <p>We encourage you to get your repeat prescriptions from your usual doctor however in case of stock outs we can offer refills while you wait to be seen by your personal doctor.</p>
                        </div>
                     </li>  
                     <li>
                        <h2>Will there be a delay in receiving my prescription?</h2>
                        <div style={{ fontSize: 18, marginBottom: 60, color: '#fff' }}>
                           <p>We will use reasonable efforts to deliver the prescription to your device or to a nearby pharmacy promptly but we are not connected with and have no control over or responsibility for any individual pharmacy, their policies in relation to acceptance or otherwise of prescriptions, opening hours, cost or availability of medicines prescribed. There are a number of factors which may impact on which pharmacy is chosen and there can be no guarantee that a pharmacy to which the prescription is delivered (if that option is chosen) will be the nearest pharmacy.</p> 
                           <p>We will use reasonable endeavors to procure the processing and dispatch of your prescription within 2 business days where the medication is in stock.</p>
                        </div>
                     </li>
                  </ul>
               </Col>
            </Row>
         </Grid>
      );
   }
}

export default FAQ;