import React from 'react';
import {Grid,Row,Col} from 'react-bootstrap';
import firebase from 'firebase/app';
import firestore from 'firebase/firestore';

class AdminUploadMeds extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         meds: '',
         error: ''
      };
   }

   componentDidMount() {
   }
   
   uploadMeds = () => {
      const { meds } = this.state;
      
      const medications = meds.split('\n');
      let isError = false;
      
      medications.forEach((med) => {
         med = med.trim();
         
         if (!isError && med.length > 0) {
            window.db
               .collection("public")
               .doc("list_data")
               .collection("drugs")
               .doc(med.replace(/\//g, "_"))
               .set({
                  name: med,
                  lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
               }, {merge: true})
               .catch((err) => {
                  isError = true;
                  this.setState({error: err.mesage});
               });
         }
      });
      
      if (!isError) {
         this.setState({error: "Complete!"});
      }
   }
   
   render() {
      const { meds, error } = this.state;
      
      return (
         <Grid>
            <Row>
               <Col xs={12}>
                  <h1>Upload Meds</h1>
                  <div style={{ marginTop: 10, marginBottom: 10 }}>Enter a list of medications (one per line).  Only medications not yet seen will be added.</div>
                  
                  {error.length > 0 && (
                     <div style={{color:"red", backgroundColor: "#fff", padding: 10, marginTop: 20, marginBottom: 20}}>{error}</div>
                  )}
                  <textarea placeholder="Meds" value={meds} onChange={(e) => this.setState({ meds: e.target.value})} style={{ width: '100%', height: 200, fontSize: 18, marginBottom: 20 }} />

                  <a href="javascript:void(0);" className="btn" style={{ marginTop: 10 }} onClick={this.uploadMeds}>Update</a>
               </Col>
            </Row>
         </Grid>
      );
   }
}

export default AdminUploadMeds;