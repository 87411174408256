import React from 'react';
import {Grid,Row,Col} from 'react-bootstrap';
const moment = require('moment');
import firebase from 'firebase/app';
import firestore from 'firebase/firestore';
import FileUploader from "react-firebase-file-uploader";

class AdminDoctorRegistration extends React.Component {
   state = {
      userId: '',
      userName: '',
      registration: {},
      notes: '',
      uploadsRef: null,
      uploads: [],
      isUploading: false,
      progress: 0
   };
   
   // Change component state based on prop changes
   componentWillReceiveProps(nextProps) { 
      const { userId, userName, registration } = nextProps;
      
      this.setState({ userId, userName, notes: '', uploads: [] });

      if (registration && registration.id) {
         this.setState({
            registration,
            displayName: (registration.displayName) ? registration.displayName : '',
            firstName: (registration.firstName) ? registration.firstName : '',
            lastName: (registration.lastName) ? registration.lastName : ''
         });
         
         //Get reviewer notes
         window.db
            .collection("doctor_registrations")
            .doc(registration.id)
            .collection("review")
            .doc("notes")
            .get()
            .then((doc) => {
               if (doc.exists) {
                  this.setState({ notes: doc.data().note });
               }
            });
         
         // Register for uploaded file changes
         const uploadsRef = window.db
            .collection("doctor_registrations")
            .doc(registration.id)
            .collection("upload")
            .limit(100)
            .onSnapshot((snapshot) => {
               // console.log(snapshot);
               if (snapshot) {
                  const changes = snapshot.docChanges();
                  changes.forEach((change) => {
                     let doc = change.doc.data();
                     
                     doc = {
                        id: change.doc.id,
                        ...doc
                     };
                     
                     let { uploads } = this.state;
                     const docs = uploads;
                     
                     if (change.type === 'added') {
                        docs.push(doc);
                     } else if (change.type === 'modified') {
                      /*
                        Request changed
                      */
                      docs.forEach((d, i) => {
                        if (d.id === doc.id) {
                          docs[i] = doc;
                        }
                      });
                    } else if (change.type === 'removed') {
                      /*
                        Request removed
                      */
                      let foundIdx = -1;
                      docs.forEach((d, i) => {
                        if (d.id === doc.id) {
                          foundIdx = i;
                        }
                      });
   
                      if (foundIdx > -1) {
                        docs.splice(foundIdx, 1);
                      }
                    }
                    
                    this.setState({ uploads: docs });
                  });  
               }
            });
         this.setState({uploadsRef});
      }
   }
   
   componentWillUnmount() {
      const { uploadsRef } = this.state;
      
      if (uploadsRef) {
         uploadsRef();
      }
   }
   
   updateNotes = () => {
      const { registration, notes } = this.state;
      
      window.db
         .collection("doctor_registrations")
         .doc(registration.id)
         .collection("review")
         .doc("notes")
         .set({
            note: notes,
            lastUpdated: firebase.firestore.FieldValue.serverTimestamp()
         }, {merge: true}).then(() => {
            alert("Updated!");
         });
   }
   
   flagAsApproved = () => {
      const { registration } = this.state;
      
      if (confirm('Are you sure you want to mark this applicant as APPROVED?')) {
         const approveDoctorRegistration = window.funcs.httpsCallable('approveDoctorRegistration');
         approveDoctorRegistration({registration}).then((result) => {
            if (result.data === "OK") {
               alert("Approval Accepted!");
            } else {
               alert('Problem encountered. ' + result.data);
            }
         }).catch((httpsError) => {
           console.log(httpsError);
         });
      }
   }
   
   flagAsRejected = () => {
      const { registration } = this.state;
      
      if (confirm('Are you sure you want to mark this applicant as REJECTED?')) {
         const rejectDoctorRegistration = window.funcs.httpsCallable('rejectDoctorRegistration');
         rejectDoctorRegistration({registration}).then((result) => {
            if (result.data === "OK") {
               alert("Rejection Successful!");
            } else {
               alert('Problem encountered. ' + result.data);
            }
         }).catch((httpsError) => {
           console.log(httpsError);
         });
      }
   }
   
   updateDisplayName = () => {
      const { registration, displayName } = this.state;
      
      window.db
            .collection("doctor_registrations")
            .doc(registration.id)
            .set({
               displayName: displayName.trim()
            }, {merge: true});
   }
   
   updateFirstName = () => {
      const { registration, firstName } = this.state;
      
      window.db
            .collection("doctor_registrations")
            .doc(registration.id)
            .set({
               firstName: firstName.trim()
            }, {merge: true});
   }
   
   updateLastName = () => {
      const { registration, lastName } = this.state;
      
      window.db
            .collection("doctor_registrations")
            .doc(registration.id)
            .set({
               lastName: lastName.trim()
            }, {merge: true});
   }
   
   setNextReviewDate = (period) => {
      const { registration } = this.state;

      if (period !== '') {
         let reviewDate = moment().utc();
         
         if (period === '1h') {
            reviewDate = moment().utc().add(1,'hour');
         } else if (period === '2h') {
            reviewDate = moment().utc().add(2,'hours');
         } else if (period === '4h') {
            reviewDate = moment().utc().add(4,'hours');
         } else if (period === '6h') {
            reviewDate = moment().utc().add(6,'hours');
         } else if (period === '12h') {
            reviewDate = moment().utc().add(12,'hours');
         } else if (period === '1d') {
            reviewDate = moment().utc().add(1,'day');
         } else if (period === '2d') {
            reviewDate = moment().utc().add(2,'days');
         } else if (period === '3d') {
            reviewDate = moment().utc().add(3,'days');
         } else if (period === '4d') {
            reviewDate = moment().utc().add(4,'days');
         } else if (period === '5d') {
            reviewDate = moment().utc().add(5,'days');
         }
         
         // Convert to javascript date
         reviewDate = firebase.firestore.Timestamp.fromDate(reviewDate.toDate());
         
         window.db
            .collection("doctor_registrations")
            .doc(registration.id)
            .set({
               nextReview: reviewDate
            }, {merge: true}).then(() => {
               //Update local registration object
               registration.nextReview = reviewDate;
               this.setState({registration});
               
               alert("Next Review Date Updated!");
            });
      }
   }
   
   handleUploadStart = () => {
      this.setState({ isUploading: true, progress: 0 });
   }
   handleProgress = (progress) => {
      this.setState({ progress });
   }
   handleUploadError = (error) => {
      this.setState({ isUploading: false });
      console.error(error);
   }
   handleUploadSuccess = (filename) => {
      const { userId, userName, registration } = this.state;
      this.setState({ progress: 100, isUploading: false });
      
      firebase
         .storage()
         .ref(`doctor_registration/${registration.id}`)
         .child(filename)
         .getDownloadURL()
         .then((url) => {
            const timestamp = firebase.firestore.FieldValue.serverTimestamp();
            
            // Retrieve a title for the uploaded file
            let title = null;
            while(title === null || title.length === 0) {
               title = prompt('Please enter a description for this file', '');
            }
            
            // Save reference to uploaded file
            window.db
            .collection("doctor_registrations")
            .doc(registration.id)
            .collection("upload")
            .add({
               title,
               url,
               uploadedBy: userName,
               uploadedById: userId,
               timestamp
            });
         });
   }
   
   render() {
      const { registration, displayName, firstName, lastName, notes, uploads, isUploading, progress } = this.state;
      
      return (
         <div>
            {registration && registration.id && (
               <div>
                  <h2>{registration.id}</h2>
                  <hr/>
                  <Row style={{ paddingTop: 15, paddingBottom: 15 }}>
                     <Col xs={6} md={2}>
                        <b>First Name</b><br/>
                     </Col>
                     <Col xs={6} md={4}>
                        <input
                           type="text"
                           value={firstName}
                           onChange={(e) => this.setState({ firstName: e.target.value})}
                           onBlur={(e) => this.updateFirstName()}
                           style={{width: '100%'}} />
                     </Col>
                     <Col xs={6} md={2}>
                        <b>Last Name</b><br/>
                     </Col>
                     <Col xs={6} md={4}>
                        <input
                           type="text"
                           value={lastName}
                           onChange={(e) => this.setState({ lastName: e.target.value})}
                           onBlur={(e) => this.updateLastName()}
                           style={{width: '100%'}} />
                     </Col>
                  </Row>
                  <Row style={{ paddingTop: 15, paddingBottom: 15 }}>
                     <Col xs={6} md={2}>
                        <b>Display Name</b><br/>
                        (e.g. Dr Joe Smith)
                     </Col>
                     <Col xs={6} md={4}>
                        <input
                           type="text"
                           value={displayName}
                           onChange={(e) => this.setState({ displayName: e.target.value})}
                           onBlur={(e) => this.updateDisplayName()}
                           style={{width: '100%'}} />
                     </Col>
                     <Col xs={6} md={2}>
                        <b>Applicant Email</b>
                     </Col>
                     <Col xs={6} md={4}>
                        {registration.applicantEmail}
                     </Col>
                  </Row>
                  <Row style={{ paddingTop: 15, paddingBottom: 15 }}>
                     <Col xs={6} md={2}>
                        <b>Applicant ID</b>
                     </Col>
                     <Col xs={6} md={4}>
                        {registration.applicantId}
                     </Col>
                     <Col xs={6} md={2}>
                        <b>Applicant Phone</b>
                     </Col>
                     <Col xs={6} md={4}>
                        {registration.applicantPhone}
                     </Col>
                  </Row>
                  <Row style={{ paddingTop: 15, paddingBottom: 15 }}>
                     <Col xs={6} md={2}>
                        <b>Practice Name</b>
                     </Col>
                     <Col xs={6} md={4}>
                        {registration.practiceName}
                     </Col>
                     <Col xs={6} md={2}>
                        <b>Practice Phone</b>
                     </Col>
                     <Col xs={6} md={4}>
                        {registration.practicePhone}
                     </Col>
                  </Row>
                  <Row style={{ paddingTop: 15, paddingBottom: 15 }}>
                     <Col xs={6} md={2}>
                        <b>Practice Address</b>
                     </Col>
                     <Col xs={6} md={4}>
                        {registration.practiceAddress}<br />
                        {registration.practiceCity}
                     </Col>
                     <Col xs={6} md={2}>
                        <b>Practice Country</b>
                     </Col>
                     <Col xs={6} md={4}>
                        {registration.practiceCountryName}
                     </Col>
                  </Row>
                  <Row style={{ paddingTop: 15, paddingBottom: 15 }}>
                     <Col xs={6} md={2}>
                        <b>GP?</b>
                     </Col>
                     <Col xs={6} md={4}>
                        {registration.gp ? "YES" : "NO"}
                     </Col>
                     <Col xs={6} md={2}>
                        <b>Specialist?</b>
                     </Col>
                     <Col xs={6} md={4}>
                        {registration.specialist ? "YES" : "NO"}
                     </Col>
                  </Row>
                  <Row style={{ paddingTop: 15, paddingBottom: 15 }}>
                     <Col xs={6} md={2}>
                        <b>Languages</b>
                     </Col>
                     <Col xs={6} md={4}>
                        {registration.languages.join(', ')}
                     </Col>
                     <Col xs={6} md={2}>
                        <b>Specialities</b>
                     </Col>
                     <Col xs={6} md={4}>
                        {registration.specialities.join(', ')}
                     </Col>
                  </Row>
                  <Row style={{ paddingTop: 15, paddingBottom: 15 }}>
                     <Col xs={6} md={2}>
                        <b>LAST UPDATED</b>
                     </Col>
                     <Col xs={6} md={4}>
                        {registration.lastUpdated && (
                           moment(registration.lastUpdated.toDate()).format('YYYY-MM-DD HH:mm')
                        )}
                     </Col>
                     <Col xs={6} md={2}>
                        <b>CREATED</b>
                     </Col>
                     <Col xs={6} md={4}>
                        {registration.dateCreated && (
                           moment(registration.dateCreated.toDate()).format('YYYY-MM-DD HH:mm')
                        )}
                     </Col>
                  </Row>
                  <Row style={{ paddingTop: 15, paddingBottom: 15 }}>
                     <Col xs={6} md={2}>
                        <b>NEXT REVIEW</b>
                     </Col>
                     <Col xs={6} md={4}>
                        {registration.nextReview && (
                           moment(registration.nextReview.toDate()).format('YYYY-MM-DD HH:mm')
                        )}
                     </Col>
                     <Col xs={6} md={2}>
                        <b>STATUS</b>
                     </Col>
                     <Col xs={6} md={4}>
                        {registration.status}
                     </Col>
                  </Row>
                  <Row style={{ paddingTop: 15, paddingBottom: 0 }}>
                     <Col xs={6} md={6}>
                        <b>Document Type</b>
                     </Col>
                     <Col xs={6} md={6}>
                        <b></b>
                     </Col>
                  </Row>
                  <Row style={{ paddingTop: 15, paddingBottom: 0 }}>
                     <Col xs={6} md={6}>
                        {registration.license && registration.license.length > 0 && (
                           <a href={registration.license} target="_blank">Practicing License</a>
                        )}
                        {(!registration.license || registration.license.length === 0) && (
                           <div>
                              Practicing License (not supplied)
                           </div>
                        )}
                     </Col>
                     <Col xs={6} md={6}>
                        
                     </Col>
                  </Row>
                  <Row style={{ paddingTop: 0, paddingBottom: 0 }}>
                     <Col xs={6} md={6}>
                        {registration.selfie && registration.selfie.length > 0 && (
                           <a href={registration.selfie} target="_blank">Selfie</a>
                        )}
                        {(!registration.selfie || registration.selfie.length === 0) && (
                           <div>
                              Selfie (not supplied)
                           </div>
                        )}
                     </Col>
                     <Col xs={6} md={6}>
                        
                     </Col>
                  </Row>
                  <Row style={{ paddingTop: 0, paddingBottom: 0 }}>
                     <Col xs={6} md={6}>
                        {registration.certificate && registration.certificate.length > 0 && (
                           <a href={registration.certificate} target="_blank">Certificate</a>
                        )}
                        {(!registration.certificate || registration.certificate.length === 0) && (
                           <div>
                              Certificate (not supplied)
                           </div>
                        )}
                     </Col>
                     <Col xs={6} md={6}>
                        
                     </Col>
                  </Row>
                  <Row style={{ paddingTop: 0, paddingBottom: 15 }}>
                     <Col xs={6} md={6}>
                        {registration.indemnity && registration.indemnity.length > 0 && (
                           <a href={registration.indemnity} target="_blank">Indemnity</a>
                        )}
                        {(!registration.indemnity || registration.indemnity.length === 0) && (
                           <div>
                              Indemnity (not supplied)
                           </div>
                        )}
                     </Col>
                     <Col xs={6} md={6}>
                        
                     </Col>
                  </Row>
                  {uploads.map((u,i) => (
                     <span key={i}>
                        {u.timestamp && (
                           <Row style={{ paddingTop: 0, paddingBottom: 15 }}>
                              <Col xs={6} md={6}>
                                 <a href={u.url} target="_blank">{u.title}</a>
                              </Col>
                               <Col xs={6} md={6}>
                                 {u.uploadedBy} @ {moment(u.timestamp.toDate()).format('YYYY-MM-DD HH:mm')}
                               </Col>
                           </Row>
                        )}
                     </span>
                  ))}
                  <Row style={{ paddingTop: 0, paddingBottom: 15 }}>
                     <Col xs={6} md={6}>
                        <label style={{backgroundColor: '#004fda', color: 'white', padding: 10, borderRadius: 4, cursor: 'pointer'}}>
                           {isUploading && (
                              <span>Uploading {progress}%</span>
                           )}
                           {!isUploading && (
                              <span>Upload file</span>
                           )}
                           <FileUploader
                              accept="image/*"
                              hidden
                              randomizeFilename
                              storageRef={firebase.storage().ref(`doctor_registration/${registration.id}`)}
                              onUploadStart={this.handleUploadStart}
                              onUploadError={this.handleUploadError}
                              onUploadSuccess={this.handleUploadSuccess}
                              onProgress={this.handleProgress}
                            />
                        </label>
                     </Col>
                     <Col xs={6} md={6}>
                     
                     </Col>
                  </Row>

                  <textarea placeholder="Notes" value={notes} onChange={(e) => this.setState({ notes: e.target.value})} style={{ width: '100%', height: 200, fontSize: 18, marginBottom: 20 }} />
                  <br />
                  <Row>
                     <Col xs={4}>
                        <a href="javascript:void(0);" className="btn" style={{ marginTop: 10 }} onClick={this.updateNotes}>Update Notes</a>
                     </Col>
                     <Col xs={4} style={{ textAlign: 'center' }}>
                        <a href="javascript:void(0);" className="btn" style={{ marginTop: 10, backgroundColor: 'black', color: '#fff' }} onClick={this.flagAsRejected}>Reject Application</a>
                     </Col>
                     <Col xs={4} style={{ textAlign: 'right' }}>
                        <a href="javascript:void(0);" className="btn" style={{ marginTop: 10, backgroundColor: 'red' }} onClick={this.flagAsApproved}>Approve Application</a>
                     </Col>
                  </Row>
                  <br />
                  
                  Set next review<br />
                  <select onChange={(e) => this.setNextReviewDate(e.target.value)}>
                     <option value="">Select</option>
                     <option value="1h">1 hour</option>
                     <option value="2h">2 hours</option>
                     <option value="4h">4 hours</option>
                     <option value="6h">6 hours</option>
                     <option value="12h">12 hours</option>
                     <option value="1d">1 day</option>
                     <option value="2d">2 days</option>
                     <option value="3d">3 days</option>
                     <option value="4d">4 days</option>
                     <option value="5d">5 days</option>
                  </select>
                  <br /><br />
               
                  
               </div>
            )}
         </div>
      );
   }
}

export default AdminDoctorRegistration;
