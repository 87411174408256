import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import firebase from 'firebase/app';
import {Grid,Row,Col} from 'react-bootstrap';
const moment = require('moment');

class Footer extends React.Component {
    constructor(){
        super();
        
        this.state = {
        };
    }
    
    render() {
      const year = moment().format('YYYY');
      return (
        <Grid>
          <Row className="footerBar">
            <Col xs={12}>
                <center>
                    <Link style={{ marginRight: 20 }} to="/privacy-policy/">Privacy Policy</Link>
                    <Link style={{ marginRight: 20 }} to="/code-of-conduct/">Code of Conduct</Link>
                    <Link to="/terms-of-use/">Terms of Use</Link>
                    <br /><br />
                    GoGP and GoGP+ are trademarks of Go Labs Inc<br />
                    Copyright {year} GoGP, All Rights Reserved
                </center>
            </Col>
          </Row>
        </Grid>
      );
    }
}

export default Footer;