import React from 'react';
import {Grid,Row,Col} from 'react-bootstrap';

class Covid19 extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         asset: {}
      };
      this.mounted = true;
   }
   
   componentDidMount() {
      const getAsset = window.funcs.httpsCallable('getCMSAsset');
      getAsset({ assetName: 'site_covid19' }).then((result) => {
         if (this.mounted) {
            this.setState({ asset: result.data });
         }
      });
   }
   
   componentWillUnmount() {
      this.mounted = false;
   }
   
   render() {
      const {asset} = this.state;
      const assetBody = asset.body && asset.body.replace(/\n/g,'<br/>').trim();
      
      return (
         <Grid>
            <Row>
               <Col xs={12}>
                  <div style={{ fontSize: 17 }}>
                     <div dangerouslySetInnerHTML={{__html: assetBody}} />
                  </div>
               </Col>
            </Row>
         </Grid>
      );
   }
}

export default Covid19;