import React from 'react';
import {Grid,Row,Col} from 'react-bootstrap';

import AdminWithdrawal from './Admin_Withdrawal';
import AdminWithdrawalManual from './Admin_Withdrawal_Manual';
import AdminWithdrawalCongo from './Admin_Withdrawal_Congo';
import AdminWithdrawalTanzania from './Admin_Withdrawal_Tanzania';
import AdminPatientLedger from './Admin_Patient_Ledger';

class AdminWithdrawals extends React.Component {
   constructor(props) {
      super(props);
      this.state = {
         searchText: '',
         withdrawals: [],
         selectedWithdrawal: {},
         selectedPatient: {},
         withdrawalsRef: null
      };
   }

   componentDidMount() {
      const withdrawalsRef = window.db
         .collection("private")
         .doc("ledger")
         .collection("withdrawal_requests")
         .where('processed', '==', false)
         .orderBy("dateCreated", "desc")
         .limit(500)
         .onSnapshot((snapshot) => {

            if (snapshot) {
               const changes = snapshot.docChanges();
               changes.forEach((change) => {
                  let request = change.doc.data();
                  
                  request = {
                     id: change.doc.id,
                     ...request
                  };
                  
                  let { withdrawals } = this.state;
                  const requests = withdrawals;
                  
                  if (change.type === 'added') {
                     requests.push(request);
                  } else if (change.type === 'modified' && !request.processed) {
                   /*
                     Request changed
                   */
                   requests.forEach((req, i) => {
                     if (req.id === request.id) {
                       requests[i] = request;
                     }
                   });
                 } else if (change.type === 'removed' || request.processed) {
                   /*
                     Request removed
                   */
                   let foundIdx = -1;
                   requests.forEach((req, i) => {
                     if (req.id === request.id) {
                       foundIdx = i;
                     }
                   });

                   if (foundIdx > -1) {
                     requests.splice(foundIdx, 1);
                   }
                   
                   // Check if this was the currently selected withdrawal
                   // If so deselect it.
                   const { selectedWithdrawal } = this.state;
                   if (selectedWithdrawal.id === request.id) {
                      this.setState({ selectedWithdrawal: {}, selectedPatient: {} });
                   }
                 }
                 
                 this.setState({ withdrawals: requests });
               });  
            }
         });
      this.setState({withdrawalsRef});
   }
   
   componentWillUnmount() {
      const { withdrawalsRef } = this.state;
      
      if (withdrawalsRef) {
         withdrawalsRef();
      }
   }
   
   selectWithdrawal = (withdrawal) => {
      this.setState({ selectedWithdrawal: withdrawal, selectedPatient: {} });

      // Retrieve patient record
      window.db
         .collection("patients")
         .doc(withdrawal.userId)
         .get()
         .then((doc) => {
            if (doc.exists) {
               const patient = doc.data();
               patient.id = doc.id;
               this.setState({ selectedPatient: patient });
            }
         });
   }
   
   render() {
      const { withdrawals, selectedWithdrawal, selectedPatient } = this.state;
      
      // Determine type of withdrawal
      let withdrawalType = 'manual';
      if (selectedWithdrawal.type === 'MM') {
         if(selectedWithdrawal && (selectedWithdrawal.MSISDN.indexOf('+242') === 0 || selectedWithdrawal.MSISDN.indexOf('242') === 0)) {
            withdrawalType = 'congo';
         } else if(selectedWithdrawal && (selectedWithdrawal.MSISDN.indexOf('+255') === 0 || selectedWithdrawal.MSISDN.indexOf('255') === 0)) {
            withdrawalType = 'tanzania';
         }
      }

      return (
         <Grid>
            <Row>
               <Col xs={12}>
                  <h1>Pending Withdrawals</h1>
               </Col>
            </Row>
            <Row>
               <Col xs={3}>
                  {withdrawals.map((w,i) => (
                     <div key={i} style={{
                        borderStyle: 'solid',
                        borderWidth: '1px',
                        borderColor: '#004fda',
                        padding: '10px',
                        backgroundColor: ((selectedWithdrawal.id === w.id) ? '#004fda' : '#296cf6')
                     }}>
                        <a href="javascript:void(0);" style={{fontWeight: 600}} onClick={() => this.selectWithdrawal(w)}>
                           {(w.name.trim().length > 0) ? w.name : (w.nickName && w.nickName.trim().length > 0) ? w.nickName.trim() : 'UNKNOWN'}
                        </a><br />
                        {w.type === 'MM' && (
                           <div>
                              MOBILE MONEY<br />
                              {w.carrierName}<br />
                              {w.MSISDN}<br />
                              <b>Credits: {w.credits}</b><br />
                              <b>Amount: {w.amount} {w.currency}</b>
                           </div>
                        )}
                        {w.type === 'PAYPAL' && (
                           <div>
                              PAYPAL<br />
                              {w.paypalAddress}<br />
                              <b>Credits: {w.credits}</b><br />
                              <b>Amount: {w.amount} {w.currency}</b>
                           </div>
                        )}
                     </div>
                  ))}
               </Col>
               <Col xs={9}>
                  <AdminWithdrawal withdrawal={selectedWithdrawal} />
                  {selectedWithdrawal.id && !selectedWithdrawal.processed && withdrawalType === 'congo' && (
                     <div>
                        <hr />
                        <AdminWithdrawalCongo withdrawal={selectedWithdrawal} />
                     </div>
                  )}
                  {selectedWithdrawal.id && !selectedWithdrawal.processed && withdrawalType === 'tanzania' && (
                     <div>
                        <hr />
                        <AdminWithdrawalTanzania withdrawal={selectedWithdrawal} />
                     </div>
                  )}
                  {selectedWithdrawal.id && !selectedWithdrawal.processed && withdrawalType === 'manual' && (
                     <div>
                        <hr />
                        <AdminWithdrawalManual withdrawal={selectedWithdrawal} />
                     </div>
                  )}
                  {selectedWithdrawal.id && (
                     <div>
                        <hr />
                        <h2>Transaction History</h2>
                        <AdminPatientLedger patient={selectedPatient} showNewEntry={false} />
                     </div>
                  )}
               </Col>
            </Row>
         </Grid>
      );
   }
}

export default AdminWithdrawals;