import React from 'react';
import {Grid,Row,Col} from 'react-bootstrap';
const moment = require('moment');
import firebase from 'firebase/app';
import firestore from 'firebase/firestore';

class AdminWithdrawalCongo extends React.Component {
   state = {
      withdrawal: {},
      amount: 0.0,
      currencyISO3: 'XAF',
      isProcessed: false,
      isBusy: false
   };
   
   componentWillReceiveProps(nextProps) { 
      const { withdrawal } = nextProps;
      
      this.setState({ withdrawal, amount: withdrawal.amount });
   }
   
   processWithdrawal = () => {
      const { withdrawal, amount, currencyISO3, isProcessed } = this.state;
      
      if (!isProcessed) {
         if (confirm(`Are you sure you want to send ${amount} ${currencyISO3} to ${withdrawal.MSISDN}?`)) {
            this.setState({
               isBusy: true
            });
            
            const momoWithdrawal = window.funcs.httpsCallable('momoWithdrawal');
            momoWithdrawal({
               withdrawalId: withdrawal.id,
               amount: parseFloat(amount),
               currencyISO3
            }).then((transInfo) => {
               this.setState({
                  isBusy: false
               });
               
               if (transInfo && transInfo.status === 'OK') {
                  // Set component flag to "processed"
                  this.setState({
                     isProcessed: true
                  });
                  
                  // THIS SHOULD BE DONE ON THE SERVER SIDE, VIA A CALLBACK
                  // Flag withdrawal as processed
                  /*
                  window.db
                     .collection("private")
                     .doc("ledger")
                     .collection("withdrawal_requests")
                     .doc(withdrawal.id)
                     .update(
                        {
                           processed: true,
                           extReference: transInfo.reference, //TODO
                           datePaid: firebase.firestore.FieldValue.serverTimestamp()
                        }
                     )
                     .then(() => {
                        // alert('Done!');
                     });
                  */
               } else {
                  alert('Problem encountered sending funds');
               }
            }).catch((httpsError) => {
              console.log(httpsError);
              
              this.setState({
                  isBusy: false
               });
            });
         } else {
            alert('Aborted!');
         }
      }
   }
   
   render() {
      const { withdrawal, amount, currencyISO3, isProcessed, isBusy } = this.state;
      
      return (
         <div>
            {withdrawal && withdrawal.id && (
               <div>
                  <h2>Withdrawal to MTN (Congo) Mobile Money</h2>
                  
                  {!isProcessed && (
                     <Row style={{ paddingTop: 15, paddingBottom: 15 }}>
                        <Col xs={2} md={2}>
                           <b>Amount</b>
                        </Col>
                        <Col xs={2} md={2}>
                           <input type="text" style={{width: '100%'}} value={amount} onChange={(e) => this.setState({ amount: parseFloat(e.target.value)})} />
                        </Col>
                        <Col xs={2} md={2}>
                           {currencyISO3}
                        </Col>
                        <Col xs={2} md={2}>
                           {!isBusy && (
                              <a href="javascript:void(0);" className="btn" style={{ marginTop: 0 }} onClick={this.processWithdrawal}>Send Funds</a>
                           )}
                           {isBusy && (
                              <b>Processing...</b>
                           )}
                        </Col>
                     </Row>
                  )}
                  {isProcessed && (
                     <Row style={{ paddingTop: 15, paddingBottom: 15 }}>
                        <Col xs={6} md={2}>
                           <b>Processed!</b>
                        </Col>
                     </Row>
                  )}
               </div>
            )}
         </div>
      );
   }
}

export default AdminWithdrawalCongo;
